@import url(https://fonts.googleapis.com/css2?family=Philosopher&family=Lora&family=Inconsolata&family=Ubuntu&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700);
@import url(https://fonts.googleapis.com/css2?family=Philosopher&family=Lora&family=Inconsolata&family=Ubuntu&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(2deg);
  }
  66% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(2deg);
  }
  66% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.m-1 {
  margin: 1rem;
}

.my-1 {
  margin: 1rem 0;
}

.mx-1 {
  margin: 0 1rem;
}

.p-1 {
  padding: 1rem;
}

.mp-1 {
  padding: 1rem 0;
}

.mp-1 {
  padding: 0 1;
}

.m-2 {
  margin: 2rem;
}

.my-2 {
  margin: 2rem 0;
}

.mx-2 {
  margin: 0 2rem;
}

.p-2 {
  padding: 2rem;
}

.mp-2 {
  padding: 2rem 0;
}

.mp-2 {
  padding: 0 2;
}

.m-3 {
  margin: 3rem;
}

.my-3 {
  margin: 3rem 0;
}

.mx-3 {
  margin: 0 3rem;
}

.p-3 {
  padding: 3rem;
}

.mp-3 {
  padding: 3rem 0;
}

.mp-3 {
  padding: 0 3;
}

.m-4 {
  margin: 4rem;
}

.my-4 {
  margin: 4rem 0;
}

.mx-4 {
  margin: 0 4rem;
}

.p-4 {
  padding: 4rem;
}

.mp-4 {
  padding: 4rem 0;
}

.mp-4 {
  padding: 0 4;
}

.m-5 {
  margin: 5rem;
}

.my-5 {
  margin: 5rem 0;
}

.mx-5 {
  margin: 0 5rem;
}

.p-5 {
  padding: 5rem;
}

.mp-5 {
  padding: 5rem 0;
}

.mp-5 {
  padding: 0 5;
}

.flex,
.flex-row,
.flex-column,
.flex-row-reverse,
.flex-column-reverse {
  overflow: auto;
  display: flex;
}

.flex-column,
.flex-row-column,
.flex-column-column,
.flex-row-reverse-column,
.flex-column-reverse-column {
  flex-direction: column;
}

.flex-column-reverse,
.flex-row-column-reverse,
.flex-column-column-reverse,
.flex-row-reverse-column-reverse,
.flex-column-reverse-column-reverse {
  flex-direction: column-reverse;
}

.flex-row-reverse,
.flex-row-row-reverse,
.flex-column-row-reverse,
.flex-row-reverse-row-reverse,
.flex-column-reverse-row-reverse {
  flex-direction: row-reverse;
}

.centered {
  align-items: center;
}

i[class^="icon-"] {
  padding-top: 0.1em;
}

i[class^="icon-"] svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
}

.wrapper-icon {
  color: currentColor;
}

.wrapper-icon svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lora", sans-serif;
}

.theme--light .text-primary {
  color: #3b3baf;
}

.theme--dark .text-primary {
  color: #3b3baf;
}

.theme--light .text-secondary {
  color: #a5b1b6;
}

.theme--dark .text-secondary {
  color: #a5b1b6;
}

.alert-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column-reverse;
}

.alert {
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.1rem 0;
  font-family: "Ubuntu", sans-serif;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #ccc;
  border: #999999 0.1em solid;
}

[class^="button"] {
  border-radius: 0.2em;
  cursor: pointer;
  display: flex;
  margin: 0.5em;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  padding: 0.5em 1em;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  outline: none;
  border: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.theme--light [class^="button"] {
  background-color: #fafafa;
  color: #003;
  box-shadow: 0.1em 0.1em 0.05em 0.05em #f2f2f2;
}

.theme--light [class^="button"]:hover, .theme--light [class^="button"]:focus, .theme--light [class^="button"]:active {
  background-color: #f2f2f2;
  color: #003;
  box-shadow: 0.1em 0.1em 0.05em 0.05em #ebebeb;
}

.theme--dark [class^="button"] {
  background-color: #27272c;
  color: #fcf;
  box-shadow: 0.1em 0.1em 0.05em 0.05em #202024;
}

.theme--dark [class^="button"]:hover, .theme--dark [class^="button"]:focus, .theme--dark [class^="button"]:active {
  background-color: #202024;
  color: #fcf;
  box-shadow: 0.1em 0.1em 0.05em 0.05em #19191c;
}

.button-darkmode {
  padding: 0.5rem;
}

.theme--light .button-darkmode {
  background-color: #fafafa;
  color: #003;
}

.theme--dark .button-darkmode {
  background-color: #27272c;
  color: #fcf;
}

a {
  color: unset;
  text-decoration: none;
}

.link {
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
  border-bottom: 2px solid blue;
  display: inline-block;
  transition: 0.2s all ease-out;
}

.link:visited {
  border-color: #c200c2;
}

.link:hover {
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s;
  color: blue;
}

.link:hover:visited {
  color: purple;
}

.loader {
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.view-super-centered {
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.view-deconstructed-pancake,
.view-deconstructed-pancake-stretch {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.view-deconstructed-pancake .pancake,
.view-deconstructed-pancake-stretch .pancake {
  flex: 0 1 150px;
  /* No stretching */
  padding: 5px;
  background-color: green;
  border-radius: 2px;
  margin: 5px;
}

.view-deconstructed-pancake-stretch .pancake,
.view-deconstructed-pancake-stretch-stretch .pancake {
  flex: 1 1 150px;
  /* Stretching */
}

.view-sidebar,
.view-sidebar-left,
.view-sidebar-right {
  display: -ms-grid;
  display: grid;
  overflow-x: hidden;
  -ms-grid-columns: minmax(150px, 25%) 1fr;
      grid-template-columns: minmax(150px, 25%) 1fr;
}

.view-sidebar-right,
.view-sidebar-left-right,
.view-sidebar-right-right {
  -ms-grid-columns: 1fr minmax(150px, 25%);
      grid-template-columns: 1fr minmax(150px, 25%);
}

.view-stacked {
  height: 100%;
  display: -ms-grid;
  display: grid;
  overflow-x: hidden;
  width: 100%;
  -ms-grid-columns: 100%;
      grid-template-columns: 100%;
  -ms-grid-rows: auto 1fr auto;
      grid-template-rows: auto 1fr auto;
}

.view-stacked main {
  padding: 1.5rem;
  height: 100%;
}

.view-classic {
  display: -ms-grid;
  display: grid;
  overflow-x: hidden;
  -ms-grid-rows: auto 1fr auto;
  -ms-grid-columns: auto 1fr auto;
      grid-template: auto 1fr auto / auto 1fr auto;
}

.view-classic header {
  padding: 2rem;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
}

.view-classic .left-side {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
}

.view-classic main {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
}

.view-classic .right-side {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
}

.view-classic footer {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
}

.view-12grid {
  display: -ms-grid;
  display: grid;
  overflow-x: hidden;
  -ms-grid-columns: (1fr)[12];
      grid-template-columns: repeat(12, 1fr);
}

.view-12grid .span-12 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/span 12;
}

.view-12grid .span-6 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-column: 1/span 6;
}

.view-12grid .span-4 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/span 4;
}

.view-12grid .span-3 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1/span 3;
}

.view-12grid .span-2 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/span 2;
}

.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  flex-wrap: wrap;
  height: auto;
  padding: 1rem 1rem;
}

.theme--light .navbar {
  background-color: #e6e6e6;
  color: #003;
  box-shadow: 0px 4px 2px -2px #2b2b83;
}

.theme--dark .navbar {
  background-color: #141416;
  color: #fcf;
  box-shadow: 0px 4px 2px -2px #2b2b83;
}

@media screen and (min-width: 501px) {
  .navbar {
    padding: 0.5rem 1rem;
  }
}

.navbar .navbar-brand {
  height: 2rem;
  font-size: 1.5rem;
  margin-right: auto;
  display: flex;
  align-items: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
}

.navbar .navbar-brand [class^="icon-"],
.navbar .navbar-brand img {
  height: 200%;
  margin-right: 1rem;
}

.navbar .navbar-brand [class^="icon-"] svg,
.navbar .navbar-brand img svg {
  height: 100%;
  width: auto;
}

@media screen and (min-width: 501px) {
  .navbar .nav-collapse-toggler {
    display: none;
  }
}

.navbar .nav-collapse-toggler .btn-line {
  width: 28px;
  height: 3px;
  margin: 0 0 5px 0;
  background: currentColor;
  transition: 0.2s all ease-out;
}

.navbar .nav-collapse-toggler.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar .nav-collapse-toggler.close .btn-line:nth-child(2) {
  transform: rotate(225deg);
  opacity: 0;
}

.navbar .nav-collapse-toggler.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.navbar .nav-collapse {
  display: none;
  transition: 0.2s all ease-out;
}

@media screen and (min-width: 501px) {
  .navbar .nav-collapse {
    display: block;
  }
}

.navbar .nav-collapse.show {
  transform: translate(0, 0);
  visibility: inherit;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 1rem;
  width: 100%;
  display: flex;
}

.theme--light .navbar .nav-collapse.show {
  background-color: #e6e6e6;
  color: #003;
}

.theme--dark .navbar .nav-collapse.show {
  background-color: #141416;
  color: #fcf;
}

.navbar .nav-collapse.show .nav-links {
  flex-direction: column;
}

.navbar .nav-links {
  display: flex;
  list-style: none;
}

.navbar .nav-links .nav-link {
  margin: 1em;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
}

.navbar .nav-links .nav-link:hover {
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s;
}

.navbar .nav-links .nav-link a:hover {
  border-bottom: 2px solid blue;
}

.navbar .nav-links .nav-link a:hover:visited {
  border-color: purple;
}

.container,
.container-grid {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.container-grid,
.container-grid-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

html {
  position: relative;
  min-height: 100%;
  display: block;
  height: 100%;
}

body {
  line-height: 1.6;
  min-height: 100%;
  background-color: white;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.westworld {
  height: 100%;
  width: 100%;
}

.theme--light .westworld {
  background-color: #fff;
  color: #003;
}

.theme--dark .westworld {
  background-color: #2c2c31;
  color: #fcf;
}

#list-of-icons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.user-profile {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 100px;
  height: 100px;
}

.user-profile img {
  border-radius: 50%;
  max-width: 100%;
}

.add-item {
  display: flex;
  flex-direction: column;
}

.done {
  text-decoration: line-through;
}

.display-items ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.display-items ul .display-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.theme--light button {
  background-color: #1d8a1d;
}

.theme--dark button {
  background-color: #1d8a1d;
}
/*# sourceMappingURL=index.css.map */
